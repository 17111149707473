.videoContainer {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
}

.videoContainer iframe,
.videoContainer object,
.videoContainer embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.videoContainerWrapper {
    max-width: 100%;
    width: 800px;
    left: 0;
    right:0;
    margin:auto;
}
