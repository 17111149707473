.content {
    content: "";
    position: fixed;
    z-index: -1;

    display: block;
    background-size: cover;
    width: 110%;
    height: 110%;
    top: -5%;
    left: -5%;
    right: 0;
    bottom: 0;

    filter: blur(15px);
}
