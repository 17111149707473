.lyrics {
    background-color: rgba(255, 255, 255, 0.6);
    text-align: center;
}

.lyrics1 {
    font-size: 30pt;
}

.lyrics1Current {
    color: blueviolet;
}

.lyrics1NotCurrent {
}

.lyrics2 {
    font-size: 23pt;
}
